export default {
  documents: [
    '.pdf',
    '.xls',
    '.xlsx',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.rtf',
    '.tif',
    '.csv',
    '.gdoc',
    '.gsheet',
  ],
  images: ['.jpeg', '.jpg', '.gif', '.png', '.tiff', '.bmp'],
  videos: [
    '.avi',
    '.wmv',
    '.asf',
    '.mpeg',
    '.mpg',
    '.vob',
    '.mod',
    '.3gp',
    '.mkv',
    '.mov',
    '.mp4',
    '.m4v',
  ],
};
